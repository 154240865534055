<template>
  <CContainer class="text-black" style="padding-bottom:100px;">
    <div
      class="card border-greyborder mt-3"
      style="padding:15px;border-radius:10px"
    >
      <div class="d-flex">
        <div class="c-avatar">
          <img
            :src="logo"
            alt=""
            class="img-fluid"
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/869/869636.png';"
          />
        </div>
        <div style="padding-left:10px">
          <h3>
            <span class="font-weight-bold"> {{ shopName }} - </span>
            <span class="h4 text-grey">{{ branchName }} </span>
          </h3>
          <small class="text-description">
            {{ businessType }}
          </small>
        </div>
      </div>
    </div>
    <div class="card border-greyborder" style="padding:15px;border-radius:10px">
      <h5 class="mb-0 font-weight-bold">ข้อมูลการติดต่อ</h5>
      <br />
      <h6 class="text-description">{{ address }}</h6>
      <h6 class="mt-3 text-description">
        <strong class="text-grey">เบอร์โทรศัพท์ : </strong> {{ tel }}
      </h6>
      <hr />
      <h5 class="mb-0 font-weight-bold">เวลาเปิด-ปิด</h5>
      <br />
      <h6 class="text-description">{{ open }}</h6>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['shopLineOA']),
    shopObjectId() {
      return this.$route.params.shopObjectId
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      let name = ''
      if (this.shop) {
        name = this.shop.shopName
      }
      document.title = name
      return name
    },
    branchName() {
      if (this.shopLineOA.length > 0) {
        let name = this.shopLineOA.filter(
          (item) => item.objectId === this.shopObjectId
        )
        return name[0].branchName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
    businessType() {
      if (this.shop) {
        if (this.shop.businessType === 0) {
          return 'ร้านค้าทั่วไป'
        } else if (this.shop.businessType === 1) {
          return 'ร้านอาหาร'
        } else if (this.shop.businessType === 2) {
          return 'Hostel'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    address() {
      if (this.shop) {
        return this.shop.address1 + this.shop.address2
      } else {
        return ''
      }
    },
    tel() {
      if (this.shop) {
        return this.shop.tel
      } else {
        return ''
      }
    },
    open() {
      if (this.shop) {
        return this.shop.open + ' - ' + this.shop.close
      } else {
        return ''
      }
    },
  },
}
</script>
